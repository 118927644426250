import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import App from '../../App';
import ReactPlayer from 'react-player';
import videoLinks from '../../config.json';
import Video from '../../interfaces/Video';
import Modal from 'react-bootstrap/Modal';
import './VideoShow.css';

export default function VideoShow() {
    const location = useLocation();
    let { slug } = useParams();
    const video = slug ? getVideoBySlug(slug) : undefined;
    const [show, setShow] = useState(true);

    const navigate = useNavigate();

    const handleClose = () => setShow(false);

    useEffect(() => {
        setShow(true);
    }, [location]);

    function getVideoBySlug(slug: string) {
        return videoLinks.videos.find((video: Video) => video.slug === slug);
    }
    return (
        <>
            <App />
            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
                className="modal-video-player"
                onExit={() => navigate('/')}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {video && video.link &&
                        <ReactPlayer playing={true}
                            url={video.link}
                            controls={true}
                            width="100%"
                            height="100%"
                        />
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}