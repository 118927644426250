import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import StripeButton from '../../Fragments/StripeButton';
import './Donate.css';
export default function Donate() {
    // create modal with about us information
    const modalRef = useRef(null);

    const location = useLocation();
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    useEffect(() => {
        setShow(true);
    }, [location]);



    return (
        <>
            <Modal
                show={show}
                className="modal-donate"
                keyboard={false}
                onHide={handleClose}
                onExit={() => navigate('/')}
            >
                <Modal.Header closeButton>
                    Support us
                </Modal.Header>
                <Modal.Body>

                <StripeButton />
                </Modal.Body>
            </Modal>
        </>
    );
}