import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';

import VideoShow from './pages/VideoShow/VideoShow';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import Layout from './Layout';
import AboutUs from './pages/AboutUs/AboutUs';
import Home from './pages/Home/Home';
import Donate from './pages/Donate/Donate';
import VideoList from './pages/VideoList/VideoList';

const router = createBrowserRouter([
    {
        element: <Layout />,
        children: [

            {
                path: '/video/:slug',
                element: <VideoShow />,
            },
            {
                path: '/about-us',
                element: <AboutUs />
            },
            {
                path: '/donate',
                element: <Donate />
            },
            {
                path: '/videos',
                element: <VideoList show={true} handleClose={() => {}} />
            },
            // default route
            {
                path: '/',
                element: <Home />,
            },
            // 404 page
            {
                path: '*',
                element: <PageNotFound />,
            },
        ],
    },

]);
export function Routes() {
    return <RouterProvider router={router} />;
}