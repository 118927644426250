import React from "react";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from 'react-bootstrap/Modal';
import "./Dropdown.css";
import videoLinks from '../config.json';
import Video from '../interfaces/Video';
import VideoList from '../pages/VideoList/VideoList';
import Cookies from 'js-cookie';
import { sha1 } from 'js-sha1';


// Video Menu dropdown

export const DropdownMenu: React.FC<{toggleMenu: any}> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [passw, setPassw] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShowLogin(false);
  const [showLogin, setShowLogin] = useState(false);

  const videoPass = "bfd8e805d8caf11857d3ccf037c88481d226cc37";


  useEffect(() => {

    // get password from cookie
    const passk = Cookies.get('passk') || '';
    setPassw(passk);


    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleResize);
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  const handleShow = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

    // if cookie 'passk' not equals password, show login modal
    if (passw !== videoPass) {
      setShowLogin(true);
      event.preventDefault();
      return
    }

    if(isMobile) {
      navigate(`/videos`);
      props.toggleMenu();
      event.preventDefault();

    }
    else {
      setDropdownOpen(!dropdownOpen);
    }

  }

  const items = videoLinks.videos.map((video, key) => (
    <Dropdown.Item className="video-item" key={key} onClick={() => {
        navigate(`/video/${video.slug}`);
    }}>
      <img src={video.thumbnail} alt={video.title} />
      <p>{video.title}</p>
    </Dropdown.Item>
  ));

  return (
    <>
      <Dropdown show={dropdownOpen} autoClose={true}>
        <Dropdown.Toggle variant="" className="nav__item" onClick={handleShow}>Videos</Dropdown.Toggle>
        <Dropdown.Menu className="video-menu">
          {items}
        </Dropdown.Menu>
      </Dropdown>
      <Modal
                show={showLogin}
                className="modal-login"
                keyboard={false}
                onHide={handleClose}
                onExit={() => navigate('/')}
            >
                <Modal.Header closeButton>
                    Login
                </Modal.Header>
                <Modal.Body>

                <h2>Enter password</h2>
                <div><input type="password" id="password" className="login-pass w-100" /></div>
                <div><button className="login-submit btn btn-primary w-100" onClick={() => {
                  let pass = (document.getElementById('password') as HTMLInputElement).value;
                  if (sha1(pass) === videoPass) {
                    Cookies.set('passk', sha1(pass));
                    setPassw(sha1(pass));
                    setShowLogin(false);

                    if(isMobile) {
                      navigate(`/videos`);    
                      props.toggleMenu();
                    }
                    else {
                      setDropdownOpen(!dropdownOpen);
                    }                  
                  }
                }}>Submit</button></div>

                </Modal.Body>
            </Modal>
    </>
  );
};



