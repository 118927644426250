import React, { useState, useRef, useEffect } from 'react';
import Spline from '@splinetool/react-spline';
import './App.css';
import Navbar from "./Navbar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const splineRef = useRef<any>(null);

  const [isMobile, setIsMobile] = useState(false);
  const [showSpline, setShowSpline] = useState(true);
  const [splineUrl, setSplineUrl] = useState("https://prod.spline.design/Z1ughUtl3ubrTWHw/scene.splinecode");


  function onLoad(spline: any) {
    splineRef.current = spline;
    const loadingMessage = document.querySelector('.loading-message');
    if (loadingMessage) {
      loadingMessage.classList.add('hide');
    }
  }
  function onSplineMouseDown(event: any) {
    console.log('object', event.target.name);
    console.log('cube', event);
  }

  function stopSplineAnimationIfNotInFocus() {
    if(!splineRef.current)  return;
    let videoDropdown = document.querySelector('.video-menu.dropdown-menu');
    let videoModal = document.querySelector('.modal-video-player');




    if ((videoDropdown && videoDropdown.classList.contains('show'))
    || (videoModal && videoModal.classList.contains('show'))) {
      splineRef.current.stop();
    }

    else if (window.location.pathname != '/') {
      splineRef.current.stop();
    }  
    else {
      splineRef.current.play();
    }
    
  }

  useEffect(() => {

    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    if(mediaQuery.matches){
      setSplineUrl("");
    }

    const handleResize = () => setIsMobile(mediaQuery.matches);


    const intervalId = setInterval(stopSplineAnimationIfNotInFocus, 300); // Check every second
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);




  return (
    <>
      <Navbar />
      <div className="App">

        <header className="App-header">

          <div className="loading-message">Loading...</div>
          <div id="spline-full">

            {!isMobile && <Spline  id="spline-component" scene={splineUrl}
              onLoad={onLoad}

            />}


          </div>


        </header>
      </div>
    </>
  );
}

export default App;
