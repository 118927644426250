import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Dropdown from "react-bootstrap/Dropdown";
import "./VideoList.css";
import videoLinks from '../../config.json';
import Video from '../../interfaces/Video';

interface VideoListProps {
    show: boolean;
    handleClose: () => void;
  }

const VideoList: React.FC<VideoListProps> = ({ show, handleClose }) => {
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
    }, [location]);

    const getVideos = () => {
        let key = 0;
        const items = (videoLinks.videos as Video[]).map((video: Video) => {
            key++;
            return (
                <div className="video-item" key={key} onClick={(e) => {

                    navigate(`/video/${video.slug}`);
                }}>
                    <img src={video.thumbnail} alt={video.title} />
                    <p>{video.title}</p>
                </div>
            );
        });

        return (
            <>
                {items}
            </>
        );
    };


    let videos = getVideos();
    return (
        <>
            <div className="video-list">
                    {videos}
            </div>
        </>
    );
}

export default VideoList;