import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import './AboutUs.css';
export default function AboutUs() {

    const location = useLocation();
    const navigate = useNavigate();


    // create modal with about us information
    const modalRef = useRef(null);

    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    useEffect(() => {
        setShow(true);
      }, [location]);

    const closeModal = () => {
        setShow(false);
    };
    return (
        <>
            <Modal
                show={show}
                backdrop="static"
                keyboard={false}
                className="modal-about-us"
                onHide={handleClose}
                onExit={() => navigate('/')}
            >
                <Modal.Header closeButton>
                    About Us
                </Modal.Header>
                <Modal.Body>
                <p>
                    <img className='about-us-logo' src="/images/about-us-logo.jpg" alt="Musicat Logo" />
                </p>
                <h2>MUSICAT & LIVE MUSIC HEALS</h2>
We are a family-owned arts and entertainment conglomerate with a social impact mission -to
address and support health literacy for youth and families to empower a stronger and healthier
generation.  We create unique wellness content through topic-curated animations, books and games,
and original lyrics and music. A unique component of Musicat’s mission is incorporating focused
education, involving youth experience ambassadors, in various stages of our productions, from
scripting, voice-over, songwriting, and public speaking.
<h2>Our Animated Cast</h2>
<p>The Musicat Gang- is a lively group of 7 hilarious humanoid musical cats- who magically transform
in shape and color when positive messages are presented to them, The Musicats each have relatable
health and wellness concerns and take their viewers on a magical educational ride demonstrating the
positive healing effects of music and the arts through their “edutaining” experiences.
The inspiration originated from a treble clef sketch drawn by our Grand Matriarch Marylou
“LuLu” Rapuano- a 4th-grade educator with a passion for music firmly believing in its science-based
ability to transform minds.</p>
<p>The Musicat production team works in partnership with top public health experts, physicians,
and organizations such as Yale Bright Bodies and The University of Maryland, Baltimore to script
clinically supported entertainment messaging that is offered to the general public, pediatric
medical facilities, schools, and community engagement centers through agreements and
partnerships worldwide.</p>
<p>Through a unique blend of science, art, and music we work to empower and advance a healthier and
stronger generation.</p>
                </Modal.Body>
            </Modal>
        </>
    );
}