import React, { useEffect, useRef } from 'react';

const StripeButton = () => {
  const stripeButtonRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;
    script.onload = () => {
      if (stripeButtonRef.current) {
        const stripeButton = document.createElement('stripe-buy-button');
        stripeButton.setAttribute('buy-button-id', 'buy_btn_1PgHNuRpKwBNYhMbmXIaTNvB');
        stripeButton.setAttribute('publishable-key', 'pk_live_51PXlFNRpKwBNYhMbTyGB6Ih2VlnoL9IrfbZhZnmgUjKyTyTwZFi23Icphij8NYEhkpNO7aIXPh7LtpjPTCdAblox004yrDhAxt');
        (stripeButtonRef.current as HTMLElement).appendChild(stripeButton);
      }
    };


    document.body.appendChild(script);

    // Cleanup the script element on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div ref={stripeButtonRef}></div>
  );
};

export default StripeButton;
